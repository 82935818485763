import { action, observable } from 'mobx';

export interface TokenData {
	token: string;
	expires: number;
}

export interface IPersistenceStore {
	setItem<T>(key: string, value: T): Promise<void>;
	getItem<T>(key: string): Promise<T | undefined>;
	hasItem(key: string): Promise<boolean>;
	removeItem(key: string): Promise<void>;
	clear(): Promise<void>;
}

export interface ITokenStore {
	setTokenData(tokenData: TokenData | undefined): void;
	getTokenData(): TokenData | undefined;
	ensureLoaded(): Promise<void>;
}

const TOKEN_LOCAL_STORAGE_KEY = 'tokenData';
export default class TokenStore {
	private loadPromise: Promise<void> | undefined;

	@observable
	private tokenData: TokenData | undefined;

	@action
	setTokenData(tokenData: TokenData | undefined) {
		this.tokenData = tokenData;
		this.persist();
	}

	@observable
	getTokenData() {
		return this.tokenData;
	}

	async persist() {
		if (this.tokenData) {
			await window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(this.tokenData));
		} else {
			window.localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
		}
	}

	async ensureLoaded() {
		if (!this.loadPromise) {
			this.loadPromise = this.load();
		}
		await this.loadPromise;
	}

	private async load() {
		const tokenData = await window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
		if (tokenData) {
			this.setTokenData(JSON.parse(tokenData));
		}
	}
}
