import React, { useEffect } from 'react';
import { useTokenStore } from '../Hooks/UseTokenStore';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

export default observer(Logout);
function Logout() {
	const tokenStore = useTokenStore();

	useEffect(() => {
		tokenStore.setTokenData(undefined);
	}, [tokenStore]);

	return <Navigate to="/login" />;
}
