import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import useAxiosClient from '../Hooks/UseAxiosClients';
import styled from 'styled-components';

interface ControlButtonsProps {
	selectedTorrentIds: number[];
}

export default function ControlButtons(props: ControlButtonsProps) {
	const { selectedTorrentIds } = props;
	const axiosClient = useAxiosClient();

	const pauseTorrents = () => {
		axiosClient.post('/action', {
			type: 'pause_torrents',
			payload: {
				ids: selectedTorrentIds.join(','),
			},
		});
	};

	const startTorrents = () => {
		axiosClient.post('/action', {
			type: 'start_torrents',
			payload: {
				ids: selectedTorrentIds.join(','),
			},
		});
	};

	const removeTorrents = () => {
		axiosClient.post('/action', {
			type: 'remove_torrents',
			payload: {
				ids: selectedTorrentIds.join(','),
			},
		});
	};

	return (
		<Container>
			<Button
				onClick={startTorrents}
				sx={{
					borderRadius: '50%',
					minWidth: '64px',
					height: '64px',
				}}
				size="medium"
				color="primary"
				variant="contained"
				disabled={selectedTorrentIds.length === 0}
			>
				<FontAwesomeIcon icon={faPlay} />
			</Button>
			<Button
				onClick={pauseTorrents}
				sx={{
					borderRadius: '50%',
					minWidth: '64px',
					height: '64px',
				}}
				size="medium"
				color="primary"
				variant="contained"
				disabled={selectedTorrentIds.length === 0}
			>
				<FontAwesomeIcon icon={faPause} />
			</Button>
			<Button
				onClick={removeTorrents}
				sx={{
					borderRadius: '50%',
					minWidth: '64px',
					height: '64px',
				}}
				size="medium"
				color="primary"
				variant="contained"
				disabled={selectedTorrentIds.length === 0}
			>
				<FontAwesomeIcon icon={faTrashCan} />
			</Button>
		</Container>
	);
}

const Container = styled.div`
	position: absolute;
	bottom: 16px;
	right: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`;
