import { CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export default function LoadingScreen() {
	return (
		<Container>
			<CircularProgress />
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100vh;
	width: 100vw;
	background-color: #fafafa;
`;
