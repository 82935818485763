import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import useAxiosClient from './Hooks/UseAxiosClients';
import { useEffect, useState } from 'react';
import LoadingScreen from './Components/LoadingScreen';
import AddMagnetButton from './Torrents/AddMagnetButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkull } from '@fortawesome/free-solid-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import ControlButtons from './Torrents/ControlButtons';
import TorrentsContainer from './Torrents/TorrentsContainer';

const mdTheme = createTheme({
	palette: {
		// primary: {
		// 	main: '#d68f00',
		// },
	},
});

function DashboardContent() {
	const [selectedTorrentIds, setSelectedTorrentIds] = useState<number[]>([]);
	return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: 'flex' }}>
				<MuiAppBar position="absolute" color="primary" elevation={0}>
					<Toolbar
						sx={{
							pr: '24px',
						}}
					>
						<FontAwesomeIcon icon={faSkull} />
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							sx={{ flexGrow: 1, marginLeft: '24px' }}
						>
							Blackbeard
						</Typography>
						<nav>
							<Link
								component={RouterLink}
								to="/logout"
								variant="button"
								color="text.primary"
								sx={{ textDecoration: 'none' }}
							>
								Logout
							</Link>
						</nav>
					</Toolbar>
				</MuiAppBar>
				<Box
					component="main"
					sx={{
						backgroundColor: (theme) =>
							theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[100],
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
					}}
				>
					<Toolbar />
					<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
						<Grid item xs={12}>
							<TorrentsContainer
								selectedTorrentIds={selectedTorrentIds}
								setSelectedTorrentIds={setSelectedTorrentIds}
							/>
						</Grid>
					</Container>
				</Box>
				<ControlButtons selectedTorrentIds={selectedTorrentIds} />
				<AddMagnetButton />
			</Box>
		</ThemeProvider>
	);
}

export default function Dashboard() {
	const [loading, setLoading] = useState(true);

	const axiosClient = useAxiosClient();

	useEffect(() => {
		axiosClient.get('/').then(() => {
			setLoading(false);
		});
	}, [axiosClient]);

	if (loading) {
		return <LoadingScreen />;
	}

	return <DashboardContent />;
}
