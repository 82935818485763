import { createContext, useContext } from 'react';
import TokenStore from '../Stores/TokenStore';

const TokenStoreContext = createContext(new TokenStore());

export function useTokenStore() {
	const tokenStore = useContext(TokenStoreContext);
	if (!tokenStore) {
		throw new Error('token store required');
	}
	return tokenStore;
}
