import React, { useCallback, useEffect, useRef, useState } from 'react';
import useInterval from 'Hooks/UseInterval';
import useAxiosClient from 'Hooks/UseAxiosClients';
import { DisplayTorrent } from 'Types/Types';
import Torrent from './Torrent';
import { Grid } from '@mui/material';

interface TorrentsContainerProps {
	setSelectedTorrentIds: React.Dispatch<React.SetStateAction<number[]>>;
	selectedTorrentIds: number[];
}

export default function TorrentsContainer(props: TorrentsContainerProps) {
	const { setSelectedTorrentIds, selectedTorrentIds } = props;
	const [torrents, setTorrents] = useState<DisplayTorrent[]>([]);

	const axiosClient = useAxiosClient();
	const lastUpdated = useRef<number>();
	const isLoading = useRef(false);

	const fullLoad = useCallback(() => {
		isLoading.current = true;
		axiosClient
			.get<DisplayTorrent[]>(`/torrent/incomplete`)
			.then((res) => {
				// set initial state
				setTorrents(res.data);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				isLoading.current = false;
			});
	}, [axiosClient]);

	useEffect(fullLoad, [fullLoad]);

	useInterval(fullLoad, 60000);

	useInterval(() => {
		if (isLoading.current) return;
		isLoading.current = true;
		const ids = torrents.filter((x) => !x.completed && !x.removed).map((torrent) => torrent.id);
		const currentTime = Math.floor(new Date().getTime() / 1000);
		const offset = (lastUpdated.current ? currentTime - lastUpdated.current : 1) + 1;
		axiosClient
			.get<DisplayTorrent[]>(`/torrent/incomplete?offset=${offset}&ids=${ids}`)
			.then((res) => {
				// update any torrents in current state that have been modified
				setTorrents((prevTorrents) => {
					const updatedTorrents = prevTorrents.map((prevTorrent) => {
						const updatedTorrent = res.data.find((torrent) => torrent.id === prevTorrent.id);
						if (updatedTorrent) {
							return updatedTorrent;
						}
						return prevTorrent;
					});
					// add any new torrents
					res.data.forEach((torrent) => {
						if (!updatedTorrents.find((updatedTorrent) => updatedTorrent.id === torrent.id)) {
							updatedTorrents.push(torrent);
						}
					});
					return updatedTorrents;
				});
				lastUpdated.current = currentTime;
			})
			.catch((err) => console.log(err))
			.finally(() => {
				isLoading.current = false;
			});
	}, 1000);

	useEffect(() => {
		setSelectedTorrentIds((selectedIds) => {
			return selectedIds.filter((x) =>
				torrents
					.filter((x) => !x.completed && !x.removed)
					.map((torrent) => torrent.id)
					.includes(x),
			);
		});
	}, [torrents, setSelectedTorrentIds]);

	const rows = torrents.filter((x) => !x.completed && !x.removed);

	return (
		<Grid rowGap={1} container>
			{rows.map((torrent) => (
				<Torrent
					torrent={torrent}
					key={torrent.id}
					onClick={() => {
						setSelectedTorrentIds((prevSelectedTorrentIds) => {
							if (prevSelectedTorrentIds.includes(torrent.id)) {
								return prevSelectedTorrentIds.filter((id) => id !== torrent.id);
							}
							return [...prevSelectedTorrentIds, torrent.id];
						});
					}}
					selected={selectedTorrentIds.includes(torrent.id)}
				/>
			))}
		</Grid>
	);
}
