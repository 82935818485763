import axios from 'axios';
import TokenStore from '../Stores/TokenStore';

export default function createAxiosClient(uri: string, tokenStore: TokenStore) {
	const client = axios.create({
		baseURL: uri,
	});

	// Add a request interceptor
	client.interceptors.request.use(
		async function (config) {
			await tokenStore.ensureLoaded();
			const token = tokenStore.getTokenData();

			if (token) {
				config.headers = {
					...config.headers,
					authorization: token ? `Bearer ${token.token}` : '',
				};
			}

			return config;
		},
		function (error) {
			// Do something with request error
			return Promise.reject(error);
		},
	);

	// redirect on unauthorized
	client.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (error.response.status === 401) {
				tokenStore.setTokenData(undefined);
				window.location.href = '/login';
			}
			return Promise.reject(error);
		},
	);

	return client;
}
