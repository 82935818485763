import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useAxiosClient from '../Hooks/UseAxiosClients';

export default function AddMagnetButton() {
	const [open, setOpen] = useState(false);
	const [magnet, setMagnet] = useState<string>('');

	const inputRef = useRef<HTMLInputElement>(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (open) {
			const timeout = setTimeout(() => {
				const inputElement = inputRef.current?.querySelector('input');
				inputElement?.focus();
			}, 200);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [inputRef, open]);

	const axiosClient = useAxiosClient();

	const addMagnet = () => {
		axiosClient
			.post('/magnet-link', {
				link: magnet,
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setMagnet('');
				handleClose();
			});
	};

	return (
		<>
			<Button
				onClick={handleClickOpen}
				sx={{
					position: 'absolute',
					bottom: 16,
					right: 16,
					borderRadius: '50%',
					minWidth: '64px',
					height: '64px',
				}}
				size="medium"
				color="primary"
				variant="contained"
			>
				<FontAwesomeIcon icon={faPlus} size={'lg'} />
			</Button>
			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogTitle>Add magnet link</DialogTitle>
				<DialogContent>
					<DialogContentText>Please enter the magnet link of the torrent you want to add.</DialogContentText>
					<TextField
						autoFocus
						ref={inputRef}
						label="Link"
						fullWidth
						margin="dense"
						variant="filled"
						value={magnet}
						onChange={(e) => setMagnet(e.target.value)}
						onKeyUp={(e) => {
							if (e.key === 'Enter') {
								addMagnet();
							}
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={addMagnet}>Add</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
