import React from 'react';
import { AxiosClientProvider } from './Hooks/UseAxiosClients';
import { Route, Routes } from 'react-router-dom';
import Login from 'Auth/Login';
import Logout from 'Auth/Logout';
import Dashboard from 'Dashboard';

function App() {
	return (
		<AxiosClientProvider>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/" element={<Dashboard />} />
			</Routes>
		</AxiosClientProvider>
	);
}

export default App;
