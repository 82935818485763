import React from 'react';
import { Box, LinearProgress, LinearProgressProps, Paper, styled, Typography } from '@mui/material';
import { DisplayTorrent } from 'Types/Types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

interface TorrentProps {
	torrent: DisplayTorrent;
	onClick: () => void;
	selected: boolean;
}

export const statusForTorrent = (torrent: DisplayTorrent): string => {
	switch (torrent.status) {
		case 0:
			return 'Stopped';
		case 1:
			return 'Queued to check files';
		case 2:
			return 'checking files';
		case 3:
			return 'Queued to download';
		case 4:
			return 'Downloading';
		case 5:
			return 'Queued to seed';
		case 6:
			return 'Seeding';
		case 7:
			return 'Torrent cant find peers';
		default:
			return 'Unknown';
	}
};

export default function Torrent(props: TorrentProps) {
	const { torrent, onClick, selected } = props;
	const percent = Math.round(torrent.percentDone * 100);
	const speedInKB = Math.round(torrent.rateDownload / 1024);
	const speedInMB = Math.round(speedInKB / 1024);
	const speed = speedInMB > 0 ? speedInMB + ' MB/s' : speedInKB + ' KB/s';

	const timeLeft = torrent.eta > 0 ? dayjs().add(torrent.eta, 'second').from(dayjs()) : '--';

	const error =
		torrent.status === 7 ? (
			<Typography variant="caption" sx={{ ml: 'auto', color: 'error.main' }}>
				{statusForTorrent(torrent)}
			</Typography>
		) : null;

	return (
		<StyledPaper
			sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '100%' }}
			selected={selected}
			key={torrent.id}
			onClick={onClick}
		>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Typography variant="h6">{torrent.name}</Typography>
				<Box sx={{ ml: 'auto', mr: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
					<Typography variant="body2">{speed}</Typography>
					<Typography variant="body2">{timeLeft}</Typography>
				</Box>
			</Box>
			<Typography variant="body1">{torrent.files.length} files</Typography>
			<LinearProgressWithLabel value={percent} />
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Typography variant="caption">{statusForTorrent(torrent)}</Typography>
				{error}
			</Box>
		</StyledPaper>
	);
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

const StyledPaper = styled(Paper)<{ selected: boolean }>`
	border: ${(props) => (props.selected ? `1px solid ${props.theme.palette.primary.main}` : '1px solid transparent')};
	&:hover {
		cursor: pointer;
	}
`;
