import { AxiosInstance } from 'axios';
import React, { ReactNode, useContext } from 'react';
import createAxiosClient from 'Utils/AxiosHelpers';
import { useTokenStore } from 'Hooks/UseTokenStore';

const axiosClientContext = React.createContext<AxiosInstance | undefined>(undefined);

export default function useAxiosClient() {
	const axiosClient = useContext(axiosClientContext);
	if (!axiosClient) {
		throw new Error(
			'axiosClient not found, useAuthenticatedAxiosClient must be used within a AxiosClientsProvider',
		);
	}
	return axiosClient;
}

const ApiRoute = '/api';
export function AxiosClientProvider(props: { children?: ReactNode | undefined }) {
	const tokenStore = useTokenStore();

	const client = createAxiosClient(ApiRoute, tokenStore);

	return <axiosClientContext.Provider value={client}>{props.children}</axiosClientContext.Provider>;
}
